import React, { useEffect } from "react"
import Header from "./Header"
import Footer from "./Footer"
import { keyRenamer } from "../../helperFunctions/keyRenamer"
/*
  Used to easily wrap everything in a wrapper div as well as including header + footer on all pages

@props: {
  children: React.ReactNode?
  metaTitle: string;
  metaDescription: string;
  slug: string;
}
*/

const Layout = ({ children, metaDescription, metaTitle, slug, schema }) => {

  useEffect(() => {
    let intervalId;
    let timeoutId;

    const initializeAdlab = () => {
      const adlab = window.adlab;
      if (adlab) {
        console.log("adlab available", adlab);
        adlab.updateConfig({
          siteId: "37",
          source: "opholdsguide",
          apiKey:
            "1090a14f920ebc54e2c8b6791dd3c9ba414da29c617615d5bdfe3f3e6ae3b17f",
        });
        adlab.updateLinks();
        clearInterval(intervalId); // stop checking once initialized
        clearTimeout(timeoutId); // stop fallback timeout
      }
    };

    // Poll for adlab every 300ms
    intervalId = setInterval(initializeAdlab, 300);

    // Stop trying after 10 seconds
    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      console.warn("adlab not available after 10 seconds");
    }, 10000);

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);


  return (
    <div className="font-body text-fontBlack bg-sand relative">
      <Header
        metaDescription={metaDescription}
        metaTitle={metaTitle}
        slug={slug}
      />
      <main>{children}</main>
      <Footer />
      {schema && (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(keyRenamer(schema.one)),
            }}
          ></script>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(keyRenamer(schema.two)),
            }}
          ></script>
        </>
      )}
    </div>
  )
}
export default Layout
